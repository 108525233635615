import { useContext } from 'react'
import slugify from 'slugify'
import { stringToId } from '../../../helpers/stringToId'
import { mixins, theme } from '../../../styles'
import { Link } from '../../atoms/Link'

import { TagCommanderContext } from '../../../contexts/tagcommander'

export interface IStackTags {
  title?: string
  tagList: {
    color?: string
    title: string
    link: string
    id?: string
  }[]
  'data-testid'?: string
  color?: string
  tmsId?: string
}

export function StackTags({
  tagList,
  title,
  color,
  tmsId = 'bloc_keyword',
  ...rest
}: IStackTags): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  const handleOnClick = (label: string) => {
    hit(
      {
        screen_clickableElementName: `${tmsId}_${slugify(label, {
          lower: true,
        })}`,
      },
      { isClickEvent: true },
    )
  }

  if (!tagList?.length) {
    return null
  }
  return (
    <>
      <div className="StackTags flex flex-column items-start justify-start" {...rest}>
        {title && <span>{title}</span>}

        <ul className="StackTags__List flex" aria-label="Hashtags associés">
          {tagList?.map((tag) => (
            <li
              className="StackTags__List__Item"
              key={`StackTag-${tag.id || stringToId(tag.link)}`}
              style={{ color: color ?? tag?.color ?? theme.cssVars.purple }}
            >
              #
              <Link
                url={tag.link}
                color={color ?? tag?.color ?? theme.cssVars.purple}
                onClick={() => handleOnClick(tag.title)}
              >
                {tag.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <style jsx>{`
        .StackTags {
          margin: 0px 30px 30px;
        }

        .StackTags__List {
          flex-wrap: wrap;
          list-style: none;
          padding: 0;
          row-gap: 8px;
        }

        .StackTags__List__Item {
          font-weight: 800;
          font-size: 17px;
          flex-wrap: wrap;
          margin-right: 1em;
        }

        .StackTags__List__Item :global(a) {
          display: inline;
        }

        span {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: var(--color-midBlue);
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .StackTags__List {
            margin-bottom: 25px;
          }
        }
      `}</style>
    </>
  )
}
